import * as React from "react"
import get from "lodash/get"
import "../scss/components/faqList.scss"

class FAQList extends React.Component {
  render() {
    const list = get(this, "props.data.listName")
    const listItems = get(this, "props.data.listItems")

    return (
      <div className="faq-list__wrapper">
        <h2 className="faq-list__title">{list}</h2>
        <ul className="faq-list flow--thick">
          {listItems.map(item => (
            <dl key={item.id} className="faq-list__item flow--thin">
              <dt className="faq-list__question">{item.faqTitle}</dt>
              <dd className="faq-list__definition">
                {item.faqText.faqText}
                {item.faqLinkCopy !== undefined &&
                  item.faqLinkCopy !== "" &&
                  item.faqLinkCopy !== null && (
                    <a className="faq-list__link" 
                       href={item.faqLink}
                       target={item.faqLinkNewTab && "_blank"} 
                       rel={item.faqLinkNewTab && "noopener noreferrer"}>
                      {item.faqLinkCopy.faqLinkCopy}
                    </a>
                  )}
              </dd>
            </dl>
          ))}
        </ul>
      </div>
    )
  }
}

export default FAQList
