import * as React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import FAQList from "../components/faqList"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/faq.scss"

class FAQPage extends React.Component {
  componentDidMount() {
    // Replacing &reg; elements
    const regElements = document.querySelectorAll("main p, main .btn, main dt, main dd"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace(/®/g, "<sup>&reg;</sup>");
    })

    const elText = document.querySelectorAll('.faq-list dd'); 
      elText.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('agronomic needs.', 'agronomic&nbsp;needs.');
    })
  }
  render() {
    const faqEquipment = get(this, "props.data.equipment")
    const faqSolutions = get(this, "props.data.simpasAppliedSolutions")

    return (
      <Layout bodyClass="faq">
        <Seo title="FAQ" description="Get answers to frequently asked questions about SIMPAS and SIMPAS-Applied Solutions (SaS)" />
        <section className="padding hero hero--faq">
          <div>
            <h1>Frequently Asked Questions</h1>
          </div>
        </section>

        <section className="questions">
          <div className="container flow--thick">
            <FAQList data={faqEquipment} />
            <FAQList data={faqSolutions} />
          </div>
        </section>

        {/* Button Block */}
        <section className="bg--blue contact">
          <div className="text-center">
            <h2>More Questions?</h2>
            <p>
              Learn how SIMPAS-applied Solutions™ Can Save You Money and Increase&nbsp;Yield.
            </p>
            <div className="container">
              <div className="text-center">
                <a className="btn" href="/contact-us/">
                  Connect with a Specialist
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* End Button Block */}
      </Layout>
    )
  }
}
export default FAQPage

export const FaqPageQuery = graphql`
  query faqPageQuery {
    equipment: contentfulList(listName: { eq: "Equipment" }) {
      id
      listName
      listItems {
        ... on ContentfulFaQs {
          id
          faqLink
          faqText {
            faqText
          }
          faqTitle
          faqLinkCopy {
            faqLinkCopy
          }
        }
      }
    }
    simpasAppliedSolutions: contentfulList(
      listName: { eq: "SIMPAS-applied Solutions™" }
    ) {
      id
      listName
      listItems {
        ... on ContentfulFaQs {
          id
          faqLink
          faqLinkNewTab
          faqText {
            faqText
          }
          faqTitle
          faqLinkCopy {
            faqLinkCopy
          }
        }
      }
    }
  }
`
